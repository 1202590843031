export const AppConfig = {
  site_name: "Level2",
  title: "Level2",
  description: "Level2",
  locale: "en",
};

export const NEXT_URL =
  "https://app.trylevel2.com" || "http://localhost:3000";

export const API_URL = "https://hub2.trylevel2.com/";
// export const ENGINE_URL = "https://ev2.mktdynamics.com/";
export const ENGINE_URL = "https://engine.trylevel2.com/";
export const REACT_APP_API_SERVER_URL = "https://app.mktdynamics.com";
export const POLYGON_IO_URL = "https://stream.trylevel2.com:6521"
