import { useMutation } from "@tanstack/react-query";
import { baseApi } from "./baseApi";

export const usePostRevokeBrokerKeys = () => {
  return useMutation({
    mutationKey: ["revoke-broker-key"],
    mutationFn: async ({ userId, key }: { userId?: string; key: string }) => {
      const response = await baseApi.post(
        `/reset_broker_configuration?user_id=${userId}&broker_id=${key}`
      );
      return response;
    },
  });
};

export const usePostSubmitBrokerKeys = () => {
  return useMutation({
    mutationKey: ["submit-broker-key"],
    mutationFn: async ({
      userId,
      key,
      brokerId,
      passphrase,
      secret,
    }: {
      userId?: string;
      key: string;
      brokerId: string;
      passphrase: string;
      secret: string;
    }) => {
      const response = await baseApi.post(
        `/save_broker_api_keys?user_id=${userId}&key=${key}&broker_id=${brokerId}&secret=${secret}&passphrase=${passphrase}`
      );
      return response;
    },
  });
};
