import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import axios from "axios";
import dotenv from "dotenv";
import jwt from "jsonwebtoken";
import { shuffle } from "lodash";
import moment from "moment";
import moment_tz from "moment-timezone";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { API_URL, ENGINE_URL } from "../config";
import {
  SharpRatioString,
  TrendingTradersTableDataKey,
  UserInfo,
} from "./types";

export let generateTokenForSocketFirstCall = () => {
  // alert("hello");
  dotenv.config();
  const socketSecret = process.env.NEXT_PUBLIC_SOCKET_SECRET;
  const expiryTime = Math.floor(Date.now() / 1000) + 60 * 60 * 2; // Expiry time (2 hours from now)
  if (socketSecret) {
    const payload = {
      exp: expiryTime,
    };
    const token = jwt.sign(payload, socketSecret, { algorithm: "HS256" });
    localStorage.setItem("tokenSocket", token);
    // console.log("tokenMe", token);

    // return token;
  }
  // else {
  //   return "";
  // }
};
export let generateTokenForSocketCheck = () => {
  if (typeof window !== "undefined") {
    let token = localStorage.getItem("tokenSocket");
    if (token) {
      return token;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
export let generateTokenForSocket = generateTokenForSocketCheck();

const InvalidSessionError = "Invalid Session. Please Login again.";
export const FX_MULTIPLIER = 1_00_000;
export const FX_MULTIPLIER_XAUUSD = 100;
export const FX_MULTIPLIER_XAGUSD = 5000;

/**
 * Returns the FX multiplier based on the provided symbol and the `general` flag.
 *
 * The function determines the appropriate multiplier by checking if the symbol is part of an FX strategy.
 * - If `general` is `false`:
 *   - For gold (XAUUSD), the multiplier is 100 (`FX_MULTIPLIER_XAUUSD`).
 *   - For silver (XAGUSD), the multiplier is 5000 (`FX_MULTIPLIER_XAGUSD`).
 * - For other FX symbols or if `general` is `true`, the multiplier is 100,000 (`FX_MULTIPLIER`).
 * - If the symbol is not part of an FX strategy, the function returns 1.
 *
 * @param {string} symbol - The financial symbol to evaluate.
 * @param {boolean} [general=false] - A flag indicating whether to use the general multiplier for metals.
 * @returns {number} - The FX multiplier associated with the symbol.
 */
export const getFxMultiplier = (symbol: string, general = false) => {
  if (getIsFxStrategy(symbol)) {
    if (isMetals_GOLD(symbol) && !general) return FX_MULTIPLIER_XAUUSD;
    if (isMetals_SILVER(symbol) && !general) return FX_MULTIPLIER_XAGUSD;
    return FX_MULTIPLIER;
  }
  return 1;
};
export const DP02 = { maxDecimalPlaces: 2, minDecimalPlaces: 0 };
export const DP22 = { maxDecimalPlaces: 2, minDecimalPlaces: 2 };
export const DP24 = { maxDecimalPlaces: 4, minDecimalPlaces: 2 };
export const DP25 = { maxDecimalPlaces: 5, minDecimalPlaces: 2 };
export const DP44 = { maxDecimalPlaces: 4, minDecimalPlaces: 4 };
export const DP66 = { maxDecimalPlaces: 6, minDecimalPlaces: 6 };

export function generateSlug(val: string) {
  return val.toLowerCase().replaceAll(" ", "-");
}

export function generateSlug2(val: string) {
  return val.toLowerCase().replaceAll(" ", "_");
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// export function formatDate(str: string) {
//   let d = new Date(str);
//   return (
//     d.getFullYear() +
//     "-" +
//     (d.getMonth() < 10 ? "0" + d.getMonth() : d.getMonth()) +
//     "-" +
//     (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
//   );
// }

/**
 * Checks if the input is a valid Date object or a valid date string.
 * A valid Date object should not be "Invalid Date".
 * @param {Date | string} date - The input date to check.
 * @returns {boolean} Returns true if the input is a valid Date object or a valid date string, otherwise false.
 */
export const isValidDate = (date: Date | string): boolean => {
  if (typeof date === "string") {
    date = new Date(date);
  } // Check if the date is a valid Date object and it's not "Invalid Date"

  return date instanceof Date && !isNaN(date as any);
};

export function formatEpochDateTime(
  str: string | number,
  format: string = "YYYY-MM-DD HH:mm",
  utc?: boolean,
  placeholder?: string
) {
  let d: any = new Date(toNumberOrNull(str, "--") * 1000);
  if (!isValidDate(d)) return placeholder || "";
  d = moment(d);
  var new_york_time = moment_tz.tz(moment(d), "America/New_York");
  var utc_tz = moment(new_york_time).local();

  //
  // if (utc) {
  //   d.utc();
  //   const isDST = moment(d).isDST();
  //   const utcOffset = isDST ?  60 : 0;
  //   const timeWithOffset = d.utcOffset(utcOffset).format(format);
  //   return timeWithOffset;
  // }
  return utc_tz.format(format);
}

export let formatEpochTime = (
  str: string | number,
  format: string = "HH:mm",
  utc?: boolean,
  placeholder?: string
) => formatEpochDateTime(str, format, utc, placeholder);

export let formatEpochDate = (
  str: string | number,
  format: string = "YYYY-MM-DD",
  utc?: boolean,
  placeholder?: string
) => formatEpochDateTime(str, format, utc, placeholder);

export const formatDate = (
  str: string | number | Date,
  format: string = "YYYY-MM-DD"
) => {
  return moment(new Date(str)).format(format);
};

export const formatDateLocal = (
  str: string | number | Date,
  format: string = "YYYY-MM-DD"
) => {
  // Convert the input date to a moment object in the local timezone
  const date = moment(new Date(str)).tz(moment.tz.guess());

  // Format the date according to the specified format
  return date.format(format);
};

export const formatTime = (
  str: string | number | Date,
  format: string = "HH:mm"
) => {
  return moment(new Date(str)).format(format);
};

export function formatDateTime(
  str: string | number | Date,
  format: string = "YYYY-MM-DD HH:mm"
) {
  let d = new Date(+str);
  return moment(d).format(format);
}

export function formatMoney(
  val: number,
  decimalPlace: number = 5,
  defaultFail?: string,
  minDecimalPlaces?: number
) {
  if (defaultFail) {
    let val_ = toNumberOrNull(val);
    return val_ === null
      ? defaultFail
      : minFixedDecimal(val_, {
          minDecimalPlaces: minDecimalPlaces ?? 0,
          maxDecimalPlaces: decimalPlace,
        }).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
  return minFixedDecimal(parseFloat(val?.toString?.()), {
    minDecimalPlaces: minDecimalPlaces ?? 0,
    maxDecimalPlaces: decimalPlace,
  }).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function formatTrade(val: number) {
  return parseInt(val?.toString())
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function ordinal_suffix_of(i: any) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export const minFixedDecimal = (
  num: number,
  options: {
    decimalPlaces?: number;
    maxDecimalPlaces?: number;
    minDecimalPlaces?: number;
    prefix?: string | number;
    suffix?: string | number;
  } = {
    decimalPlaces: 2,
    maxDecimalPlaces: 4,
    minDecimalPlaces: 2,
  },
  defaultFail?: any
) => {
  num = toNumberOrNull(num);
  if (num === null) return defaultFail || "";
  return (
    (options?.prefix || "") +
    num.toLocaleString("en-US", {
      minimumFractionDigits: options.minDecimalPlaces ?? 2,
      maximumFractionDigits: options.maxDecimalPlaces ?? 4,
    }) +
    (options?.suffix || "")
  );
};

export function abbrNum(val: number, decPlaces: number) {
  let number = toNumberOrNull(val);
  if (number === null) return "N/A";
  number = +number?.toFixed(decPlaces);
  decPlaces = Math.pow(10, decPlaces);
  const abbrev = ["k", "m", "b", "t"];
  let abbr = "";
  for (let i = abbrev.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }
      abbr = abbrev[i];
      break;
    }
  }
  return number + abbr;
}

export function timeConverter(timestamp: any) {
  if (timestamp == null || timestamp == "") {
    return false;
  }
  var a = new Date(timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    month +
    " " +
    ordinal_suffix_of(date) +
    ", " +
    year +
    " " +
    hour +
    ":" +
    min +
    ":" +
    sec;
  return time;
}

export function randomString(len: number, charSet?: string) {
  charSet = charSet || "abcdefghijklmnopqrstuvwxyz";
  var randomString = "";
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
}

export function trim(str: string) {
  if (str == undefined) {
    return "";
  }
  str = str.replace(/^\s+|\s+$/g, "");
  if (str == "") {
    return "";
  }
  return str;
}

export function excerpt(str: string) {
  if (trim(str) == "") {
    return;
  }
  return str.substring(0, 60) + "...";
}

export function getTimezones() {
  const timezones = [
    "Greenwich Mean Time (GMT)",
    "Universal Coordinated Time (GMT)",
    "European Central Time (GMT+1:00)",
    "Eastern European Time (GMT+2:00)",
    "(Arabic) Egypt Standard Time (GMT+2:00)",
    "Eastern African Time (GMT+3:00)",
    "Middle East Time (GMT+3:30)",
    "Near East Time (GMT+4:00)",
    "Pakistan Lahore Time (GMT+5:00)",
    "India Standard Time (GMT+5:30)",
    "Bangladesh Standard Time (GMT+6:00)",
    "Vietnam Standard Time (GMT+7:00)",
    "China Taiwan Time (GMT+8:00)",
    "Japan Standard Time (GMT+9:00)",
    "Australia Central Time (GMT+9:30)",
    "Australia Eastern Time (GMT+10:00)",
    "Solomon Standard Time (GMT+11:00)",
    "New Zealand Standard Time (GMT+12:00)",
    "Midway Islands Time (GMT-11:00)",
    "Hawaii Standard Time (GMT-10:00)",
    "Alaska Standard Time (GMT-9:00)",
    "Pacific Standard Time (GMT-8:00)",
    "Phoenix Standard Time (GMT-7:00)",
    "Mountain Standard Time (GMT-7:00)",
    "Central Standard Time (GMT-6:00)",
    "Eastern Standard Time (GMT-5:00)",
    "Indiana Eastern Standard Time (GMT-5:00)",
    "Puerto Rico and US Virgin Islands Time (GMT-4:00)",
    "Canada Newfoundland Time (GMT-3:30)",
    "Argentina Standard Time (GMT-3:00)",
    "Brazil Eastern Time (GMT-3:00)",
    "Central African Time (GMT-1:00)",
  ];

  return timezones;
}

export const formatAmountValue = (value: any) => {
  if (value) {
    return value.toLocaleString("en-IN");
  } else {
    return 0;
  }
};
/**
 * Functions for Backtest los and metrics calculation.
 */
const getFilteredData = (data: any[], startDate: any, endDate: any) => {
  if (!startDate || !endDate) {
    return data;
  }
  return data.filter((item: any) => {
    const itemDate = item.time;
    return +itemDate >= +startDate && +itemDate <= +endDate;
  });
};
export const calculateMatrices = (
  backTestLogs: any[],
  sd: number | null,
  ed: number | null,
  historicalPerformanceData: { time?: any[] }
) => {
  const matricesData: any = {
    total: 0,
    win_p: 0,
    win: 0,
    loss: 0,
    SR: 0,
    avg_trades_per_day: 0,
    win_streak: 0,
    loss_streak: 0,
    drawdown_percent: 0,
  };
  if (!backTestLogs || !backTestLogs?.length) return matricesData;
  if (sd === null) sd = backTestLogs[0].time as number;
  if (ed === null) ed = backTestLogs[backTestLogs.length - 1].time as number;
  let initDate = new Date(sd * 1000),
    lastDate = new Date(ed * 1000),
    // Average trades per day.
    tradesEachDay: any[] = [];
  initDate.setHours(0, 0, 0, 0);
  lastDate.setHours(24, 0, 0, -1);
  let initEndTime = new Date(initDate);
  const filteredLogs = backTestLogs;
  while (initEndTime < lastDate) {
    initDate.setHours(initDate.getHours() + 24);
    initEndTime.setHours(initEndTime.getHours() + 24);
    let tradeThisDay = filteredLogs.reduce(
      (acc, log) =>
        acc +
        (log.time >= +initDate / 1000 && log.time <= +initEndTime / 1000
          ? 1
          : 0),
      0
    );
    tradesEachDay.push(tradeThisDay);
  }
  matricesData.avg_trades_per_day =
    tradesEachDay.reduce((acc, curr) => acc + curr, 0) / tradesEachDay.length;
  // Total trades
  matricesData.total = filteredLogs.length;
  // Win %
  const winLogs = filteredLogs?.filter(
    (inst: any) => inst.outcome === "SUCCESS"
  );
  matricesData.win_p = (100 * winLogs.length) / filteredLogs?.length;
  // Total win
  matricesData.win = winLogs.length;
  // Total loss
  matricesData.loss = matricesData.total - matricesData.win;
  // Sharp ratio dependencies, drawdown % and Win & loss streak
  let win_streak = 0,
    loss_streak = 0,
    current_streak = "",
    temp_win_streak = 0,
    temp_loss_streak = 0;
  let invest_peak = 0,
    invest_trough = 0;
  let filteredLogTPL = filteredLogs.map((log) => {
    let current_output = 0;
    if (log["outcome"] === "SUCCESS") {
      current_output = log["close"] - log["Take Profit"];
      if (current_output < 0) {
        current_output = -current_output;
      }
    } else {
      current_output = log["close"] - log["Stop Loss"];
      if (current_output > 0) {
        current_output = -current_output;
      }
    }
    // For Win/Loss streak start
    if (current_output > 0) {
      if (current_streak === "win") {
        temp_win_streak++;
      } else {
        temp_win_streak++;
        loss_streak = Math.max(loss_streak, temp_loss_streak);
        temp_loss_streak = 0;
      }
    } else if (current_output < 0) {
      if (current_streak === "loss") {
        temp_loss_streak++;
      } else {
        temp_loss_streak++;
        win_streak = Math.max(win_streak, temp_win_streak);
        temp_win_streak = 0;
      }
    }
    // For Win/Loss streak end
    // For Drawdown dependencies start
    if (invest_trough === 0) {
      invest_trough = current_output;
    }
    if (current_output > invest_peak) {
      invest_peak = current_output;
    }
    if (current_output < invest_trough) {
      invest_trough = current_output;
    }
    // For Drawdown dependencies end
    return current_output;
  });
  const standardDeviation = getStandardDeviation(filteredLogTPL);
  matricesData.drawdown_percent = drawdownPercentage(
    invest_peak,
    invest_trough
  );
  matricesData.win_streak = win_streak;
  matricesData.loss_streak = loss_streak;
  const filteredHData: any[] =
    historicalPerformanceData?.time?.filter(
      // @ts-ignore
      (timestamps: any) => timestamps >= +sd && timestamps <= +ed
    ) || [];
  // Sharp ratio
  matricesData.SR = getSharpRatio(
    standardDeviation,
    matricesData.win_p / 100,
    filteredHData?.length || 0
  );
  return matricesData;
};

export function convertIntToHMSString(value: any) {
  let sign = !isNaN(value) && value < 0 ? "-" : "";
  value *= sign ? -1 : 1;
  let duration = "";
  let days, hours, minutes, seconds;
  days = Math.trunc(value / 86400).toString();
  hours = Math.trunc((value % 86400) / 3600).toString();
  minutes = Math.trunc((value % 3600) / 60).toString();
  seconds = Math.trunc(value % 60).toString();
  duration += days.length == 1 ? "0" + days : days;
  duration += ":" + (hours.length == 1 ? "0" + hours : hours);
  duration += ":" + (minutes.length == 1 ? "0" + minutes : minutes);
  duration += ":" + (seconds.length == 1 ? "0" + seconds : seconds);
  duration = sign + duration;
  return duration;
}

export function convertIntToDayString(value: any) {
  let sign = !isNaN(value) && value < 0 ? "-" : "";
  value *= sign ? -1 : 1;
  let duration = "";
  let days;
  days = Math.trunc(value / 86400).toString();
  duration += days.length == 1 ? "0" + days : days;
  duration = sign + duration;
  return duration;
}

export function convertIntToHoursString(value: any) {
  let sign = !isNaN(value) && value < 0 ? "-" : "";
  value *= sign ? -1 : 1;
  let duration = "";
  let hours;
  hours = Math.trunc((value % 86400) / 3600).toString();
  duration += hours.length == 1 ? "0" + hours : hours;
  duration = sign + duration;
  return duration;
}

export function convertIntToMinutesString(value: any) {
  let sign = !isNaN(value) && value < 0 ? "-" : "";
  value *= sign ? -1 : 1;
  let duration = "";
  let minutes;
  minutes = Math.trunc((value % 3600) / 60).toString();
  duration += minutes.length == 1 ? "0" + minutes : minutes;
  duration = sign + duration;
  return duration;
}

export function convertIntToSecondString(value: any) {
  let sign = !isNaN(value) && value < 0 ? "-" : "";
  value *= sign ? -1 : 1;
  let duration = "";
  let seconds;
  seconds = Math.trunc(value % 60).toString();
  duration += seconds.length == 1 ? "0" + seconds : seconds;
  duration = sign + duration;
  return duration;
}

export function convertIntToMSString(value: any) {
  let sign = !isNaN(value) && value < 0 ? "-" : "";
  value *= sign ? -1 : 1;
  let duration = "";
  let minutes, seconds;
  minutes = Math.trunc((value % 3600) / 60).toString();
  seconds = Math.trunc(value % 60).toString();
  duration += minutes.length == 1 ? "0" + minutes : minutes;
  duration += " : " + (seconds.length == 1 ? "0" + seconds : seconds);
  duration = sign + duration;
  return duration;
}

export function base64ToArray(base64: any) {
  var binaryString = atob(base64);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export const getBrokerConnectionDetails = async (
  u: UserInfo,
  brokerId: string
) => {
  const cb = createPromiseCallback();
  if (!u?.user_id) return;
  const url = new URL("get_connected_broker_details", API_URL);
  url.searchParams.append("user_id", u.user_id as string);
  url.searchParams.append("broker_id", brokerId);
  axios
    .get(url.toString(), {
      headers: {
        Authorization: `Bearer ${u?.token}`,
      },
    })
    .then((res) => {
      if (res.statusText === "OK") {
        if (res.data) {
          cb(null, res.data);
        }
      }
    })
    .catch((e) => {
      console.log(e.message);
      cb(null, {});
    });
  return cb.promise;
};

export async function getAccountBalances(
  u: any,
  setAccountBalance: any,
  text: string
) {
  if (!u?.user_id) return;
  setAccountBalance((prev: any) => ({
    ...prev,
    apiCalling: true,
  }));
  var url = new URL("get_account_balances", API_URL);
  url.searchParams.append("user_id", u.user_id as string);
  await axios
    .get(url.toString(), {
      headers: {
        Authorization: `Bearer ${u?.token}`,
      },
    })
    .then(async (res) => {
      if (res.statusText === "OK") {
        if (res.data) {
          const result = res?.data || {};
          let _accountBalance: any = [];
          // result?.virtual_funds?.balance?.length && result.virtual_funds.balance.forEach((fund: any) => {
          //   _accountBalance.push({
          //     type: text,
          //     currency: "$",
          //     amount: fund?.balance_amount
          //   })
          // })

          const virtual_funds: any = {
            total: 0,
            totalDeployed: 0,
            funds: 0,
          };
          const live_funds: any = [];
          const resLen = result?.length;
          if (resLen) {
            for (const data of result) {
              try {
                if (data.type === "broker") {
                  const details = await getBrokerConnectionDetails(
                    u,
                    data.broker_id
                  );
                  live_funds.push({
                    broker_name: data.broker_name,
                    broker_image: data.broker_image,
                    broker_details: details,
                    // "margin": data.payload.margin,
                    // "main": data.payload.main,
                    // "trading": data.payload.trading,
                    broker_id: data.broker_id,
                    payload: data.payload,
                  });
                  const trading = data.payload.main || {};
                  const margin_c = data.payload.margin || {};
                  if (Object.keys(trading).length) {
                    for (let key of Object.keys(trading)) {
                      _accountBalance.push({
                        type: data.broker_name,
                        currency: key,
                        amount: trading[key],
                        last_equity: margin_c[key],
                      });
                    }
                  }
                }
              } catch (e) {
                console.log(e);
              }
            }
          }
          setAccountBalance((prev: any) => ({
            ...prev,
            connectedBrokers: _accountBalance,
            virtualFunds: virtual_funds,
            liveFunds: live_funds,
            loading: false,
            apiCalling: false,
          }));
        }
      }
    })
    .catch((e) => {
      console.log(e.message);
      // toast.error(e.response.data.detail);
      // if (e.response.data.detail == "Invalid Session. Please Login again.") {
      //     router.push("/logout")
      // }
    })
    .finally();
}

export function ButtonLoaderComponent() {
  return (
    <div className="h-5">
      <svg
        role="status"
        className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 dark:fill-white fill-red-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        ></path>
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        ></path>
      </svg>
    </div>
  );
}

export function percentageCalculation(current: any, previous: any) {
  var percent = ((current - previous) / current) * 100 || 0;
  if (current >= previous) {
    //+ve sign should be displayed
    if (percent < 0) {
      percent = percent * -1;
    }
  }
  return percent;
}

export function removeSeconds(d: string) {
  return d.substring(0, 16);
}

export const createBadgesFromData = (badges: any) => {
  return (
    Object.keys(badges)?.map((badge: any, index: number) => {
      let color = "";
      switch (badge.toLowerCase()) {
        case "indicator":
          color = "border-indicators text-indicators hover:text-indicators ";
          break;
        case "data":
          color = "border-data text-data hover:text-data ";
          break;
        case "pattern":
          color = "border-white text-white hover:text-white";
          break;
        case "trading logic":
          color =
            "border-tradingLogic text-tradingLogic hover:text-tradingLogic ";
          break;
        case "action":
          color = "border-action text-action hover:text-action ";
          break;
        default:
          color = "border-green text-green hover:text-green";
      }
      return badges[badge]?.map((badgeName: string, i: number) => (
        <div className="mr-2" key={index + "-" + i}>
          <button
            className={`border ${color} w-fit px-3 py-1 text-xs rounded-full transition-colors duration-500 hover:bg-transparent mr-2`}
          >
            {badgeName}
          </button>
        </div>
      ));
    }) || []
  );
};
export const Pagination = ({
  totalCount,
  currentPage,
  changePage,
  limit = 10,
  alwaysVisible,
}: {
  totalCount: number;
  currentPage: number;
  changePage: (page: number) => any;
  limit?: number;
  alwaysVisible?: boolean;
}) => {
  const numberOfPages = Math.ceil(totalCount / limit);

  const renderNumericButton = useCallback(
    (page: number, currentPage: number) => (
      <Button
        key={page}
        onClick={() => changePage(page)}
        disabled={currentPage === page}
        variant="outline"
      >
        <span className="">{page}</span>
      </Button>
    ),
    [changePage]
  );

  const renderPaginationRange = useCallback(
    (start: number, end: number) =>
      Array.from({ length: end - start + 1 }, (_, index) =>
        renderNumericButton(start + index, currentPage)
      ),
    [currentPage, renderNumericButton]
  );

  return (
    <>
      {(numberOfPages > 1 || alwaysVisible) && (
        <div className="flex flex-col justify-end flex-wrap md:flex-row w-full text-sm">
          <div className="flex gap-1">
            <Button
              onClick={() => changePage(currentPage - 1)}
              size="icon"
              variant="outline"
              disabled={currentPage < 2}
            >
              <FaChevronLeft />
            </Button>
            {currentPage <= 3
              ? renderPaginationRange(1, Math.min(5, numberOfPages))
              : renderPaginationRange(
                  Math.max(1, currentPage - 2),
                  Math.min(currentPage + 2, numberOfPages)
                )}
            <Button
              onClick={() => changePage(currentPage + 1)}
              size="icon"
              variant="outline"
              disabled={currentPage >= numberOfPages}
            >
              <FaChevronRight />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export const PaginationButton = ({
  currentPage,
  totalResults,
  onPageChange,
}: {
  currentPage: number;
  totalResults: number;
  onPageChange: (page: number) => any;
}) => {
  const resultsPerPage = 20;

  const handlePrevious = () => {
    onPageChange(currentPage - resultsPerPage);
  };

  const handleNext = () => {
    onPageChange(currentPage + resultsPerPage);
  };

  return (
    <div className="px-8 w-full justify-end flex gap-6 items-center">
      <Button
        variant="outline"
        onClick={handlePrevious}
        disabled={currentPage === 0}
      >
        <FaChevronLeft />
      </Button>

      <Button
        variant="outline"
        onClick={handleNext}
        disabled={totalResults < 10}
      >
        <FaChevronRight />
      </Button>
    </div>
  );
};

export const getStockLogos = async (symbols: string[], setState?: Function) => {
  //@ts-ignore
  let uniqueSymbols = [...new Set(symbols)];
  if (uniqueSymbols.length > 0) {
    return axios
      .post("https://app.mktdynamics.com/api/get_multiple_stock_logos", {
        tickers: uniqueSymbols.toString(),
      })
      .then((r: any) => {
        setState?.(r.data);
        return r.data;
      })
      .catch((e) => {
        console.error(e);
        throw new Error(e.message);
        return {};
      });
  }
};

export const getOrderType = (data: any) => {
  let allDecision = "";
  let parsedData = JSON.parse(data?.deployment_meta.strategy.strategy_data);
  parsedData = parsedData?.[0];
  const key = parsedData?.action[0];
  allDecision = parsedData?.action
    .map((nodeId: string) => parsedData?.payload[nodeId]?.decision || "")
    .filter((decision: string) => decision)
    .join("/");
  let firstDecision = parsedData?.payload[key]?.decision;

  let size = parsedData?.payload[key]?.other_inputs.filter(
    (inst: any) => inst.name === "Size"
  );
  let stopLoss = parsedData?.payload[key]?.other_inputs.find(
    (inst: any) =>
      (inst.name === "Stop Loss" || inst.name === "Stop Loss %") &&
      inst.value !== ""
  );
  let isStopLossPercent = stopLoss?.name === "Stop Loss %";
  return {
    dicison: firstDecision,
    allDecision,
    size: parseFloat(size?.[0]?.value),
    stopLoss: parseFloat(stopLoss?.value),
    isStopLossPercent,
  };
};

export const rotateArray = (array: any[], rotation: number) => {
  rotation = rotation % array.length;
  let removedItems = array.splice(array.length - rotation, rotation);
  array.unshift(...removedItems);
  return array;
};

type Decision = "Buy" | "Sell";
type DecisionResult = "P" | "L";

interface signal {
  order_complete_date: number;
  time?: number;
  result: DecisionResult;
  result_value: number;
  signal_close: number;
  signal_high: number;
  signal_low: number;
  signal_open: number;
  stop_loss: number;
  take_profit: number;
}

interface historicalGraphData {
  time: number[];
  olhc: {
    close: number[];
    high: number[];
    low: number[];
    open: number[];
    volume: number[];
  };
}

// let x = AlgorithmData.payload[0].signals;
const getRandomDecision: () => Decision = (() => {
  let decisions: Decision[] = ["Buy", "Sell"];
  return () => decisions[Math.floor(Math.random() * decisions.length)];
})();
const calcTakeProfitValue = (
  decision: Decision,
  tp_perct: number,
  close_value: number
) => {
  switch (decision) {
    case "Buy":
      return tp_perct * close_value + close_value;
    case "Sell":
      return close_value - tp_perct * close_value;
  }
};
const calcStopLossValue = (
  decision: Decision,
  tp_perct: number,
  close_value: number
) => {
  switch (decision) {
    case "Buy":
      return tp_perct * close_value + close_value;
    case "Sell":
      return close_value - tp_perct * close_value;
  }
};

export interface HistoricalData {
  time: number[];
  olhc: {
    open: number[];
    close: number[];
    high: number[];
    low: number[];
    value: number[];
  };
}

interface signalPayloadData {
  [key: string]: signal;
}

/**
 * Creates a promise-based callback pattern.
 * @returns {{
 *   (err?: any, data?: any): void,
 *   promise: Promise<any>
 * }}
 * @example
 * const callback = createPromiseCallback();
 * callback.promise
 *   .then((data) => {
 *     // Handle success
 *   })
 *   .catch((error) => {
 *     // Handle error
 *   });
 *
 * // To invoke the callback
 * callback(null, result); // Success
 * callback(error); // Error
 */
export const createPromiseCallback: <T = any>() => {
  (err?: any, data?: T): void;
  promise: Promise<T>;
} = () => {
  let cb: any;
  const promise = new Promise((res, rej) => {
    cb = (error: any, data: any) => {
      if (error) rej(error);
      res(data);
    };
  });
  cb.promise = promise;
  return cb;
};

export const monteCarlosAlgorithm = (
  signals: signalPayloadData,
  createdTradesAt: number[] | string[],
  historicalData?: HistoricalData,
  principalAmount: number = 0
) => {
  // Initializing the final data
  // let principalAmount = 1000;
  // run a loop to find close value for first signal
  //
  let qty = 1;
  if (principalAmount > 0) {
    let close_value_index =
      historicalData?.time.findIndex((time) => +createdTradesAt[0] === time) ??
      -1;
    if (close_value_index > -1)
      // @ts-ignore
      qty = principalAmount / historicalData?.olhc.close[close_value_index];
    else principalAmount = 0;
  }
  let signalsLength = createdTradesAt.length;

  // let index = historicalData?.olhc?.close.findIndex((inst:any)=>inst === signals)
  // let closeValue = historicalData.olhc.close[0]
  let graphData: any[] = [];
  historicalData?.time.forEach((timestamp) => {
    if (signals[timestamp]) {
      principalAmount +=
        // @ts-ignore
        (signals[createdTradesAt.shift()].result_value ?? 0) * qty;
      graphData.push(principalAmount);
    } else {
      if (
        createdTradesAt.length === 0 ||
        signalsLength === createdTradesAt.length
      )
        graphData.push(principalAmount);
      else graphData.push(null);
    }
  });
  /* createdTradesAt.forEach((timestamp: number | string) => {
        principalAmount += (signals[timestamp].result_value ?? 0) * qty;
        graphData.push(principalAmount);
      }) */
  return graphData;
};

export const generateMonteCarlosGraphRawData = (
  graphCount: number,
  // @ts-ignore
  // signals: signalPayloadData = AlgorithmData.payload[0]
  //   .signals2 as signalPayloadData,
  signals: signalPayloadData,
  historicalData?: HistoricalData,
  principalAmount: number = 0
) => {
  let createdTradesAt: string[] = Object.keys(signals);
  if (!createdTradesAt.length) return [];
  createdTradesAt.sort();
  return Array.from(
    Array(graphCount),
    (_, k: number) =>
      // k === 0
      //   ? monteCarlosAlgorithm(
      //       signals,
      //       [...createdTradesAt],
      //       historicalData,
      //       principalAmount
      //     )
      //   : monteCarlosAlgorithm(
      //       signals,
      //       shuffle([...createdTradesAt]),
      //       historicalData,
      //       principalAmount
      //     )
      // monteCarlosAlgorithm(
      //         signals,
      //         [...createdTradesAt],
      //         historicalData,
      //         principalAmount
      //       )
      monteCarlosAlgorithm(
        signals,
        shuffle([...createdTradesAt]),
        historicalData,
        principalAmount
      )
    // .map((val: any, index: number) => ({ // comment this map for chartjs and keep uncommented for lightweight chart
    //   time: +createdTradesAt[index],
    //   value: val
    // }))
  );
};
export const getDrawdownValue = (prices: number[]) => {
  let peak = 0;
  let n = prices.length,
    worstDrawdown = 0,
    bestDrawdown = Infinity;

  // best is minimum and worst is max

  //for avg, find worst dd,for each value of i in the for loop and then do an avg
  for (let i = 1; i < n; i++) {
    if (!isNaN(prices[peak]) && !isNaN(prices[i])) {
      let dif = prices[peak] - prices[i];
      if (peak < dif) {
        peak = dif;
      }
      if (!isNaN(worstDrawdown) && !isNaN(dif)) {
        worstDrawdown = worstDrawdown > dif ? worstDrawdown : dif;
        bestDrawdown = bestDrawdown > dif && dif > 0 ? dif : bestDrawdown;
      }
    }
  }
  return { worstDrawdown, bestDrawdown };
};

export const randomDate = (start: any, end: any) => {
  let dateString =
    start.getTime() + Math.random() * (end.getTime() - start.getTime());
  let someDate = Math.trunc(new Date(dateString).getTime() / 1000);
  return someDate;
};

export const formatToDecimalPlaces = (
  value: number,
  decimalPlace: number = 4
) => {
  let x = Math.pow(10, decimalPlace);
  return (Math.round(value * x) / x).toFixed(decimalPlace);
};

export const copy = (text: string, message: string, toast: any) => {
  // Get the text
  var copyText = `${text} ${window.location.origin}/build`;

  // Copy the text inside the copyText
  navigator.clipboard.writeText(copyText);
  toast({
    title: message,
  });
};

export const openInNewTab = (url: any) => {
  let document = window?.document;
  let ele = document.createElement("a");
  ele.setAttribute("rel", "noopener,noreferrer");
  ele.setAttribute("target", "_blank");
  ele.setAttribute("href", url);
  ele.click();
};

export const openTwitterInNewTab = (text: string) => {
  let _text = new URLSearchParams();
  _text.append("text", `${text} ${window.location.origin}/build`);
  openInNewTab(`https://twitter.com/intent/tweet?${_text}`);
};

export const openWhatsappInNewTab = (text: string) => {
  let _text = new URLSearchParams();
  _text.append("text", `${text} ${window.location.origin}/build`);
  openInNewTab(
    `https://api.whatsapp.com/send/?${_text}&type=custom_url&app_absent=0`
  );
};

export const getSharpRatio = (
  returns: number,
  win_pct: number,
  days: number,
  rf: number = 3.849
) => {
  if (returns * Math.sqrt(days) !== 0) {
    return (win_pct * 100 - rf) / (returns * Math.sqrt(days));
  }
  return 0;
};

export const getStandardDeviation = (arr: any) => {
  let mean =
    arr.reduce((acc: any, curr: any) => {
      return acc + curr;
    }, 0) / arr.length;

  arr = arr.map((k: any) => {
    return (k - mean) ** 2;
  });

  let sum = arr.reduce((acc: any, curr: any) => acc + curr, 0);
  return Math.sqrt(sum / arr.length);
};

export const drawdownPercentage = (invest_peak = 0, invest_trough = 0) => {
  if (invest_peak !== 0)
    return ((invest_peak + invest_trough) / invest_peak) * 100;
  return 0;
};

export const formatNumber = (num: any, fdp?: number) => {
  if (num != null) {
    return (!isNaN(fdp as number) ? num?.toFixed(fdp) : num)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "N/A";
  }
};
export const calculateChange = (forward: any, backtest?: any) => {
  if (
    !isNaN(toNumberOrNull(forward, "--")) &&
    !isNaN(toNumberOrNull(backtest, "--"))
  ) {
    const val = ((forward - backtest) / backtest) * 100;
    const val2: ReturnType<typeof toNumberAndSign> & { formatted?: string } =
      toNumberAndSign(val);
    val2.formatted = formatNumber(val2.value, 2) + "%";
    return val2;
  } else {
    return null;
  }
};

export const extractVideoId = (url: string) => {
  // Extract the video ID from the vimeo video link
  const regex = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  const match = url.match(regex);

  if (match && match[2]) {
    return match[2];
  } else {
    // Handle invalid or unsupported vimeo video link
    return null;
  }
};

export const toNumberAndSign = (val: number) => {
  let isNegative = val < 0,
    value = Math.abs(val);
  return { isNegative, value, nativeValue: val };
};

/**
 * Converts a value to a number or returns null (or a provided default value) if the value cannot be converted to a number.
 *
 * @function toNumberOrNull
 * @param {any} value - The value to convert to a number.
 * @param {number|null} [defaultValue=null] - The default value to return if the conversion fails. Defaults to null.
 * @returns {number|null} - The numeric representation of the value, or null (or the provided default) if the conversion fails.
 *
 * @example
 * const inputValue = "42";
 * const result = toNumberOrNull(inputValue, null); // Returns the number 42
 *
 * const invalidValue = "not a number";
 * const defaultResult = toNumberOrNull(invalidValue, 0); // Returns the default value 0
 *
 * const noDefault = "not a number";
 * const noDefaultResult = toNumberOrNull(noDefault); // Returns null (default not provided)
 */
export const toNumberOrNull = (value: any, defaultValue: any = null) => {
  const numericValue = parseFloat(value);
  if (!isNaN(numericValue) && !isNaN(+value) && numericValue === +value)
    return numericValue;
  return defaultValue;
};

export const useDebounce = (func: any, delay: any) => {
  const timeoutRef: any = useRef(null);
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  return (...args: any) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};
export const useDebounceNew = (
  func: (...args: any[]) => void,
  delay: number
) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (...args: any[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const useToastHelper = () => {
  const { toast } = useToast();
  const Toast = toast.bind(toast);
  Object.assign(Toast, {
    success: (msg: string, desc?: string) =>
      toast({
        title: msg,
        description: desc,
      }),
    error: (msg: string, desc?: string) =>
      toast({
        title: msg,
        description: desc,
        variant: "destructive",
      }),
  });
  return {
    toast: Toast as typeof Toast & {
      success: (msg: string, desc?: string) => null;
      error: (msg: string, desc?: string) => null;
    },
  };
};
export const getNameInitals = (user: any) => {
  if (user?.first_name?.length > 0 && user?.first_name != "") {
    return (
      user?.first_name[0]?.toUpperCase() +
      (user?.last_name[0]?.toUpperCase() || "")
    );
  }
  return user?.email?.[0]?.toUpperCase();
};
export const fetchColor = <T extends Record<string, string>>(
  colorMap: T
): T => {
  const computedColors = {} as T;
  // Loop through the colorMap and compute the actual colors
  for (const key in colorMap) {
    if (colorMap.hasOwnProperty(key)) {
      const element = document.createElement("div");
      element.style.color = colorMap[key];
      document.body.appendChild(element);
      computedColors[key as keyof T] = getComputedStyle(element)
        .color as T[keyof T];
      document.body.removeChild(element);
    }
  }

  return computedColors;
};

export const getUser = () => {
  try {
    if (typeof window !== "undefined") {
      let user = JSON.parse(localStorage.getItem("user-info")!);
      return user as {
        user: {
          email: string;
          first_name: string;
          last_name: string;
          unique_key: string;
          profile_image: string;
          trading_challenge: string;
          description?: string;
          is_active: boolean;
        };
        preference: {
          country: string;
          trading_yoe: string;
          risk_class: string;
          language: string;
          asset_class: string;
          notification_preference: null;
          nudges_tracker?: string;
          walkthrough_status?: string;
          webhook?: string;
          send_email?: string;
        };
        is_old_user: boolean;
        user_id: string;
        hmac: string;
        token: string;
        subscription: string;
        start_date: string;
        redirect_after_login: string;
      } | null;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const retryAsyncOperation = async (
  asyncFunction: () => Promise<any>,
  callback: (err: Error | null, data: any) => any,
  delay: number | number[],
  maxRetryCount: number = 3
): Promise<void> => {
  // debugger
  const [delays, maxRetry] = Array.isArray(delay)
    ? [delay, maxRetryCount || delay.length]
    : [[delay], maxRetryCount ?? 3];
  for (let retryCount = 0; retryCount < maxRetry; retryCount++) {
    try {
      const result = await asyncFunction();
      callback(null, result); // Call the callback with success
      return;
    } catch (error) {
      // If this is the last retry, rethrow the error
      if (retryCount === maxRetry - 1) {
        callback(error as any, null); // Call the callback with error
        return;
      }

      // Wait for the specified delay before the next retry
      const currentDelay = delays[Math.min(retryCount, delays.length - 1)];
      await new Promise((resolve) => setTimeout(resolve, currentDelay * 1000));
    }
  }
};

export const monthName = (
  val: number | string,
  monthStartsFromZero: boolean = true
) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (!monthStartsFromZero) (val as number) -= 1;
  return monthNames[val as number];
};

/**
 * Calculated the Win-Loss based on order type and values given
 * @param {"Buy"|"Sell"} decision Decision
 * @param {number} cp Current price
 * @param {number} op Order price
 * @param  {number} qty Quantity
 * @returns {null | Object<{isNegative: boolean; value: number; nativeValue: number;}>}
 */
export const calculateWinLossWithData = (
  symbol: string,
  decision: "Buy" | "Sell" | "buy" | "sell",
  cp: number,
  op: number,
  qty: number
) => {
  decision = decision?.toLowerCase?.() as "buy" | "sell";
  if (
    // @ts-ignore
    [cp, op, qty].includes(null) ||
    isNaN(cp) ||
    isNaN(op) ||
    isNaN(qty) ||
    !["buy", "sell"].includes(decision)
  )
    return null;
  let value =
    decision === "buy"
      ? +cp - +op // Buy case formula
      : +op - +cp; // Sell case formula
  value *= qty;
  const multiplier = getFxMultiplier(symbol);
  if (getIsFxStrategy(symbol)) {
    value *= multiplier;
    if (!symbol?.endsWith("USD")) value /= cp;
  }
  return toNumberAndSign(value);
};
/**
 * Calculated the Win-Loss based on order type and values given
 * @param {string} symbol Strategy symbol
 * @param {number} cp Current price
 * @param {"Buy"|"Sell"} decision Decision
 * @param {number} closedOrders Closed Orders
 * @param  {number[]} opList Order price list
 * @returns {null | Object<{isNegative: boolean; value: number; nativeValue: number;}>}
 */
export const calculateTotalWinLossWithData = (
  symbol: string,
  cp: number,
  decision: "Buy" | "Sell" | "buy" | "sell",
  closedOrders: number | null,
  opList: number[],
  qtyList: number[]
) => {
  decision = decision?.toLowerCase?.() as "buy" | "sell";
  if (
    // @ts-ignore
    cp === null ||
    isNaN(cp) ||
    !["buy", "sell"].includes(decision) ||
    !Array.isArray(opList) ||
    opList.some((p) => isNaN(p) || p == null) ||
    qtyList.some((p) => isNaN(p) || p == null) ||
    opList.length !== qtyList.length
  ) {
    if (closedOrders !== null) {
      return toNumberAndSign(closedOrders);
    }
    return null;
  }
  const multiplier = getFxMultiplier(symbol);
  const v1 = opList.reduce((sum, opInst, index) => {
    let val = decision === "sell" ? opInst - cp : cp - opInst;
    val *= qtyList[index];
    if (getIsFxStrategy(symbol)) {
      val *= multiplier;
      if (!symbol?.endsWith("USD")) val /= cp;
    }
    return sum + val;
  }, 0);
  if (closedOrders === null) return toNumberAndSign(v1);
  return toNumberAndSign(v1 + closedOrders);
};
export const stripPioSymbolFromSymbol = (symbol: string) => {
  if (symbol?.toLowerCase?.()?.startsWith("c:")) {
    symbol = symbol.replace(/^C\:/i, "");
    let center = Math.floor(symbol.length / 2);
    let p1 = symbol.slice(0, center),
      p2 = symbol.slice(center);
    return p1 + "/" + p2;
  }
  return symbol;
};
export const InvalidSessionAxiosCatchHandler = (
  d: any,
  cb?: (...pr: any[]) => any
) => {
  if (
    d?.response?.status === 400 &&
    d.response?.data?.detail === InvalidSessionError
  ) {
    if (cb) {
      cb?.();
    } else if (typeof window !== "undefined")
      window.location.pathname = "/logout";
  } else throw d;
};

export const getIsFxStrategy = (inst: string) => {
  if (inst?.endsWith?.("=X")) return true;
  if (inst?.startsWith?.("C:")) return true;
  if (inst?.endsWith?.("USDT")) return true;

  return false;
};
export const getDPFor = (symbol: string) => {
  if (getIsFxStrategy(symbol))
    return symbol.match(/(XAUUSD)|(USDJPY)/gi)
      ? { DPs: 2, DPPs: DP22 }
      : { DPs: 4, DPPs: DP44 };
  if (getIsCrypto(symbol)) return { DPs: 4, DPPs: DP44 };
  return { DPs: 2, DPPs: DP22 };
};
export const splitCurrencyPair = (inst: string): [string, string] => {
  if (getIsFxStrategy(inst)) {
    inst = inst.replace(/(^(C:))|((=X)$)/gi, "");
    return [inst.substring(0, 3), inst.substring(3)];
  } else if (getIsCrypto(inst))
    // Considering all crypto pairs are in format of "BTC-USDT"
    // So, splitting by "-" will give us the currency pairs
    return inst.split("-") as [string, string];
  return [inst, ""];
};
export const isMetals_GOLD = (symbol: string) => symbol === "C:XAUUSD";
export const isMetals_SILVER = (symbol: string) => symbol == "C:XAGUSD";
export const getIsCrypto = (inst: string) => {
  if (inst?.endsWith?.("-USDT")) return true;
  return false;
};

export const useMediaQuery = (query: string): boolean => {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener("change", handleChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
};
export const useWindowWidth = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    // Set initial width
    setWidth(window.innerWidth);

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

export const useIsMobile = (initialValue: boolean = false): boolean => {
  const [isMobile, setIsMobile] = useState(initialValue);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(
        typeof window !== "undefined" &&
          (window.innerWidth < 768 ||
            !!window?.navigator?.userAgentData?.mobile)
      );
    };

    // Check on mount and resize
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    // Cleanup
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return isMobile;
};
export const runBacktest = async (
  strData: {
    name: string;
    keygen: string;
    canvasData: string;
    strategy_data: string;
  },
  onSuccess?: (...x: any[]) => any,
  onError?: (err?: any) => any
) => {
  const user = getUser();
  const formData = new FormData();
  let errorFlag = false;
  formData.append("strategy_name", strData.name);
  formData.append("user_id", user?.user_id || "");
  formData.append("strategy_keygen", strData.keygen);
  formData.append("strategy_data", strData.strategy_data);
  formData.append("data", strData.canvasData);
  try {
    var url = new URL("backtest_queue", ENGINE_URL);
    let cb = createPromiseCallback();
    const action = (queueId: string, attempt: number) => {
      if (attempt == 4) {
        onError?.("Max attempt reach");
        return;
      }

      let get_report_url = new URL("/backtest_status", ENGINE_URL);
      get_report_url.searchParams.set("task_id", queueId);
      setTimeout(() => {
        axios
          .get(get_report_url.toString(), {
            headers: { Authorization: `Bearer ${user?.token}` },
          })
          .then(async (res: any) => {
            let data = res.data;
            if (typeof data === "string") {
              data = JSON.parse(data);
            }
            if (!data?.payload) return action(queueId, attempt + 1);
            return cb(null, data);
          })
          .catch((e) => {
            console.error("error", e);
            cb(e);
          });
      }, 2000);
    };
    await axios
      .post(url.toString(), formData, {
        headers: { Authorization: `Bearer ${user?.token}` },
      })
      .then((res) => {
        if (res.statusText === "OK") {
          if (typeof res.data === "string") {
            action(res.data, 0);
          } else if (res.data.error) onError?.(res.data.error);
        }
      })
      .catch(InvalidSessionAxiosCatchHandler);
    cb.promise
      .then(async (data) => {
        const formData = new FormData();
        formData.append("keygen", strData.keygen);
        formData.append(
          "backtest_report",
          JSON.stringify(data.payload, null, 2)
        );

        onSuccess?.(data);
      })
      .catch(InvalidSessionAxiosCatchHandler);
  } catch (e) {
    console.error(e);
    onError?.(e);
  }
};
export interface ReportData {
  /* metrics: { */
  SR: string;
  loss: number;
  strat_performance: number;
  win: number;
  win_p: number;
  loss_p: number;
  total: number;
  sortino: number;
  drawdown_percent: number;
  calmar: number;
  profit_factor: number;
  total_profit: number;
  total_loss: number;
  /* };
  payload: {
    order_status: "closed";
    tp: string;
    sl: string;
    decision: "Buy" | "Sell";
    trade_taken_at_hr: string;
    trade_taken_at: number;
    close_price: number;
    high_price: number;
    low_price: number;
    open_price: number;
    order_buy_id: number;
    order_close_id: number;
    trade_closed_at: number;
    trade_closed_at_hr: string;
    result: number;
    outcome: "SUCCESS" | "LOSS";
  }[]; */
}
export const calculateMetrics = (reportData: ReportData[]) => {
  let initVal = {
    SR: "",
    drawdown_percent: 0,
    loss: 0,
    "loss%": 0,
    loss_p: 0,
    loss_streak: 0,
    sortino: 0,
    calmar: 0,
    total: 0,
    win: 0,
    "win%": 0,
    win_p: 0,
    win_streak: 0,
    mean_avg_return_p: 0,
    avg_trades_per_day: 0,
    strat_performance: 0,
    profit_factor: 0,
    total_profit: 0,
    total_loss: 0,
  };
  reportData =
    typeof reportData === "string" ? JSON.parse(reportData) : reportData;
  let reportLen = reportData.length;
  for (let i = 0; i < reportLen; i++) {
    let metrics = reportData[i];
    // let payload = reportData[i].payload;
    initVal.SR = initVal.SR || metrics?.SR;
    initVal.calmar += toNumberOrNull(metrics?.calmar, 0);
    initVal.drawdown_percent += toNumberOrNull(metrics?.drawdown_percent, 0);
    initVal.loss += toNumberOrNull(metrics?.loss, 0);
    initVal.loss_p += toNumberOrNull(metrics?.loss_p, 0);
    // initVal.loss_streak += toNumberOrNull(metrics?.loss_streak,0);
    initVal.sortino += toNumberOrNull(metrics?.sortino, 0);
    initVal.total += toNumberOrNull(metrics?.total, 0);
    initVal.win += toNumberOrNull(metrics?.win, 0);
    initVal.win_p += toNumberOrNull(metrics?.win_p, 0);
    initVal.strat_performance += toNumberOrNull(metrics?.strat_performance, 0);
    /* const { profit = 0, loss = 0 } =
      payload?.reduce(
        (acc, curr) => {
          if (curr.result > 0) acc.profit += curr.result;
          else if (curr.result < 0) acc.loss += curr.result * -1;
          return acc;
        },
        { profit: 0, loss: 0 }
      ) || {};
    initVal.total_profit += profit;
    initVal.total_loss += loss; */
    // initVal.mean_avg_return_p += metrics.mean_avg_return_p;
    // initVal.win_streak += metrics.win_streak;
    // initVal.avg_trades_per_day += metrics.avg_trades_per_day;
  }
  if (reportLen > 1) {
    initVal.loss_p = initVal.loss_p / reportLen;
    initVal.loss_streak = initVal.loss_streak / reportLen;
    initVal.win_p = initVal.win_p / reportLen;
    initVal.win_streak = initVal.win_streak / reportLen;
    initVal.drawdown_percent = initVal.drawdown_percent / reportLen;
    initVal.mean_avg_return_p = initVal.mean_avg_return_p / reportLen;
    initVal.strat_performance = initVal.strat_performance / reportLen;
    initVal.drawdown_percent = initVal.drawdown_percent / reportLen;
  }
  initVal.SR = initVal.SR || SharpRatioString.DEFAULT;
  initVal.profit_factor = initVal.total_profit / initVal.total_loss;
  return initVal;
};

/**
 * Finds the first key-value pair present in the object based on the provided keys.
 * @param {Record<string, any>} obj - The object to search for key-value pairs.
 * @param {string[]} keys - The array of keys to search for in the object.
 * @returns {{ key: string | null, value: any }} An object containing the first key and its corresponding value found in the object.
 */
export const whatFirstKeyValueIsPresent = (
  obj: Record<string, any>,
  keys: string[]
) => {
  let key: string | null = null,
    value = null;
  if (
    (key =
      keys.find((_key) => {
        if (
          typeof obj[_key] !== "undefined" &&
          obj[_key] !== null &&
          obj[_key] !== ""
        )
          return _key;
      }) || null)
  )
    value = obj[key];
  return { key, value };
};

/**
 * Retrieves the label and value for take profit from the provided object.
 * @param {Record<string, any>} obj - The object containing take profit related data.
 * @param {(str: string) => string} t - The translation function for translating labels.
 * @returns {{ tp_label: string, tp_value: any }} An object containing the label and value for take profit.
 */
export const getTpLabelValue = (
  obj: Record<string, any>,
  t: (str: string) => string
) => {
  let tp_label = "";
  let { key, value } = whatFirstKeyValueIsPresent(obj || {}, [
    "take_profit_p",
    "take_profit_pips",
    "take_profit_price",
    "take_profit",
  ]);
  switch (key) {
    case "take_profit_p": {
      tp_label = t("profitTargetPercent");
      value = value + "%";
      break;
    }
    case "take_profit_pips": {
      tp_label = t("profitTargetPips");
      break;
    }
    case "take_profit_price": {
      tp_label = t("profitTargetPrice");
      break;
    }
    default: {
      // take_profit
      tp_label = t("profitTarget");
    }
  }
  return { tp_label, tp_value: value };
};

/**
 * Retrieves the label and value for stop loss from the provided object.
 * @param {Record<string, any>} obj - The object containing stop loss related data.
 * @param {(str: string) => string} t - The translation function for translating labels.
 * @returns {{ sl_label: string, sl_value: any }} An object containing the label and value for stop loss.
 */
export const getSlLabelValue = (
  obj: Record<string, any>,
  t: (str: string) => string
) => {
  let sl_label = "";
  let { key, value } = whatFirstKeyValueIsPresent(obj || {}, [
    "stop_loss_p",
    "stop_loss_pips",
    "stop_loss_price",
    "stop_loss",
  ]);
  switch (key) {
    case "stop_loss_p": {
      sl_label = t("stopLossPercent");
      value = value + "%";
      break;
    }
    case "stop_loss_pips": {
      sl_label = t("stopLossPips");
      break;
    }
    case "stop_loss_price": {
      sl_label = t("stopLossPrice");
      break;
    }
    default: {
      // stop_loss
      sl_label = t("stopLoss");
    }
  }
  return { sl_label, sl_value: value };
};

/**
 * Joins an array of strings while filtering out null and undefined values.
 * @param {Array<string | null | undefined>} strs - The array of strings to join.
 * @param {string} opr - The separator to use for joining. Default is "-".
 * @returns {string} The joined string.
 */
export const joinValidString = (
  strs: (string | null | undefined)[],
  opr: string = "-"
) => {
  if (Array.isArray(strs)) {
    return strs
      .filter((str) => str !== null && str !== undefined && str !== "")
      .join(opr);
  }
  return "";
};

export const getRelativeTimeDifference = (epochTimeString: string) => {
  const currentDate = moment();

  // Convert epoch time string to number and then to moment object
  const epochTime = parseInt(epochTimeString, 10);
  const parsedDate = moment.unix(epochTime);

  // Calculate the difference between current date and provided date in years, months, and days
  const years = currentDate.diff(parsedDate, "years");
  const months = currentDate.diff(parsedDate, "months");
  const days = currentDate.diff(parsedDate, "days");

  // Logic to determine how to express the age
  if (years > 0) {
    return years === 1 ? `(${years} year ago)` : `(${years} years ago)`;
  } else if (months > 0) {
    return months === 1 ? `(${months} month ago)` : `(${months} months ago)`;
  } else {
    return days === 1 ? `(${days} day ago)` : `(${days} days ago)`;
  }
};

export const action = (performActivity: any, duration: number) => {
  setTimeout(performActivity, duration);
};

export const getShadowColor = (type: any, name: string = "") => {
  switch (type) {
    case "Action":
      if (["Smart Sell", "Smart Buy", "Smart Exit Position"].includes(name))
        return {
          boxShadow: "rgb(25 67 138 / 16%) 0px 0px 16px 10px",
        };
      return {
        boxShadow: "0px 0px 16px 10px rgba(255, 105, 105,0.16)",
      };
    case "Market data":
      return {
        boxShadow: "0px 0px 16px 10px rgba(13, 148, 103,0.16)",
      };

    case "Indicator":
      return {
        boxShadow: "0px 0px 16px 10px rgba(174, 187, 255,0.16)",
      };
    case "Pattern":
    case "Candle Pattern":
      return {
        boxShadow: "0px 0px 16px 10px rgba(22, 22, 22,0.16)",
      };

    case "Trading logic":
      return {
        boxShadow: "0px 0px 16px 10px rgba(237, 201, 13,0.16)",
      };

    case "Data":
      return {
        boxShadow: "0px 0px 16px 10px rgba(13, 148, 103,0.16)",
      };
  }
};

export const getLineColor = (type: any, name?: any) => {
  switch (type) {
    case "Action":
      if (["Smart Sell", "Smart Buy", "Smart Exit Position"].includes(name))
        return "#19438A";
      return "rgb(255, 105, 105)";
    case "Market data":
      return "rgb(13, 148, 103)";
    case "Data":
      return "rgb(13, 148, 103)";
    case "Indicator":
      return "rgb(174, 187, 255)";
    case "Pattern":
    case "Candle Pattern":
      return "rgb(22, 22, 22)";
    case "Trading logic":
      return "rgb(237, 201, 13)";
  }
};

export const getValidSymbolImgUrl = (str: any, darkTheme: boolean = false) => {
  let img = darkTheme
    ? "/images/logos/Symbol_Dark.png"
    : "/images/logos/Symbol_Light-30.png";
  if (typeof str === "string") {
    if (str.includes("level2_compressed.png")) return img;
    return str;
  }
  return img;
};

export const simulateKey = (
  keyCode: number,
  type?: "down" | "up" | "press",
  modifiers: { [key: string]: any } = {}
) => {
  let evtName = typeof type === "string" ? "key" + type : "keydown";
  try {
    const modifier: { [key: string]: any } =
      typeof modifiers === "object" ? modifiers : {};
    const event: any = document.createEvent("HTMLEvents");
    // @ts-ignore
    event?.initEvent(evtName, true, false);
    // @ts-ignore
    event.keyCode = keyCode;
    for (let i in modifier) {
      try {
        event[i] = modifier[i];
      } catch (e) {
        console.error(e);
      }
    }
    document.dispatchEvent(event);
  } catch (e) {
    console.error(`Error dispatching keyboard '${evtName}' event.`);
  }
};

export const checkTradingLogic = (title: string) => {
  if (
    title === "Lower Than" ||
    title === "Lower Than Equal To" ||
    title === "Below" ||
    title === "Crosses Below"
  ) {
    return true;
  } else {
    return false;
  }
};
export const getInputHintByInputLabel = (label: string) => {
  switch (label) {
    case "Take Profit":
    case "Take Profit Price":
      return "Either Take Profit Price or Take Profit Percentage can be set.";
    case "Take Profit Pips":
      return "Either Take Profit Pips or Take Profit Percentage can be set.";
    case "Stop Loss Price":
      return "Either Stop Loss Price or Stop Loss Percentage can be set.";
    case "Stop Loss Pips":
    case "Stop Loss":
      return "Either Stop Loss Pips or Stop Loss Percentage can be set.";
    default:
      return "";
  }
};
export const getInputLabel = (label: string) => {
  return (
    <div className="flex gap-2 items-center">
      <Label className="capitalize mb-1 mt-2">{label}</Label>
    </div>
  );
};

export const getConnectedName = (
  id: string,
  port: string,
  nodes: any,
  edges: any
) => {
  const schema = [...nodes, ...edges];
  const source: any = schema?.find((i) => i?.targetHandle === id);
  const sourceName = schema?.find((i) => (i as any)?.id === source?.source);
  const extra =
    sourceName?.data?.outputs_list[
      sourceName?.data?.outputs?.findIndex(
        (i: any) => i?.id === source?.sourceHandle
      )
    ]?.split("_");

  const extraText = extra?.length > 1 ? `_${extra[1]?.toUpperCase()}` : "";

  const period = sourceName?.data?.other_inputs?.find(
    (i: any) => i?.name === "period" || i?.name === "Number"
  )?.value;
  let sourceNameAbbrv =
    sourceName?.data?.title
      ?.replace("/", " ")
      .split(" ")
      .map((i: any) => i[0])
      .join("") ?? port;

  return period
    ? `${sourceNameAbbrv}${extraText}[${period}]`
    : `${sourceNameAbbrv}${extraText}`;
};

/**
 * Calculates the result for JPY currency symbols based on the provided parameters.
 *
 * This function performs a specific calculation if the given symbol is related to
 * an FX strategy and includes "JPY". It checks if the `op` and `volume` values
 * are valid numbers and performs the calculation accordingly.
 *
 * @param {string} symbol - The currency symbol (e.g., "USDJPY").
 * @param {number} result - The base result value to be adjusted.
 * @param {number} order_price - The order price value used in the calculation.
 * @param {number} closed_price - The order price value used in the calculation.
 * @param {number} volume - The volume value used in the calculation.
 *
 * @returns {number} The calculated result if the symbol is for JPY and valid, otherwise returns the base result.
 */
export const calculateResultForJpy = (
  symbol: string,
  result: number,
  order_price: number,
  closed_price: number,
  volume: number
) => {
  if (getIsFxStrategy(symbol)) {
    if (
      [
        toNumberOrNull(order_price),
        toNumberOrNull(volume),
        toNumberOrNull(result),
      ].includes(null)
    )
      return result;
    let val = result;
    if (!symbol?.endsWith?.("USD")) val /= closed_price;
    return val;
  }
  return result;
};
export const createUserDetailedData = (data: any) => {
  type table_data_item = { pnl: number; total_trades: number; user_id: string };
  type table_data_user = {
    created_at: string;
    description: string;
    first_name: string;
    last_name: string;
    unique_key: string;
    user_id: string;
    contains_forex_strategies: string;
  };
  const { table_data, user_data, selected_key, contains_forex_strategies } =
    data as {
      table_data: Record<TrendingTradersTableDataKey, table_data_item[]>;
      user_data: table_data_user[];
      selected_key: TrendingTradersTableDataKey;
      contains_forex_strategies: number[];
    };

  const userData: Record<string, table_data_user> = user_data?.reduce?.(
    (acc, user) => {
      acc[user.user_id] = user;
      return acc;
    },
    {} as Record<string, table_data_user>
  );
  const forexData = contains_forex_strategies?.reduce?.(
    (acc: any, user: any) => {
      acc[user] = true;
      return acc;
    },
    {}
  );
  const findRespectiveValue = (
    key: TrendingTradersTableDataKey,
    user_id: string
  ) => {
    const entry = table_data?.[key]?.find(
      (item) => item.user_id?.toString?.() === user_id?.toString?.()
    );
    return entry ? { total_trades: entry.total_trades, pnl: entry.pnl } : "N/A";
  };

  const result = [];
  for (const item of table_data?.[selected_key] || []) {
    const user = userData[item.user_id];
    const forex = forexData?.[item?.user_id] || false;
    if (user) {
      result.push({
        ...user,
        containForexStrategy: forex,
        data: {
          [TrendingTradersTableDataKey.Three]: findRespectiveValue(
            TrendingTradersTableDataKey.Three,
            item.user_id
          ),
          [TrendingTradersTableDataKey.Seven]: findRespectiveValue(
            TrendingTradersTableDataKey.Seven,
            item.user_id
          ),
          [TrendingTradersTableDataKey.Fifteen]: findRespectiveValue(
            TrendingTradersTableDataKey.Fifteen,
            item.user_id
          ),
          [TrendingTradersTableDataKey.Thirty]: findRespectiveValue(
            TrendingTradersTableDataKey.Thirty,
            item.user_id
          ),
        },
      });
    }
  }
  return result;
};
export const convertToEpoch = (dateString: string) => {
  const date = new Date(dateString);
  return Math.trunc(date.getTime() / 1000);
};

export const getLogos = (res: any, setLogos: any) => {
  //@ts-ignore
  let uniqueSymbols = [...new Set(res)];
  if (uniqueSymbols.length > 0) {
    let url = new URL("get_logos", API_URL);
    url.searchParams.append("symbols", uniqueSymbols.toString());
    axios
      .get(url.toString(), {})
      .then((r: any) => {
        var logos_map = new Map();
        for (var item in r.data.data) {
          logos_map.set(item, r.data.data[item]);
        }
        setLogos(logos_map);
      })
      .catch((e) => {
        console.error(e);
      });
  }
};

export const addMonthsFromDate = (dateString: string, months: number) => {
  const date = new Date(dateString);
  date.setMonth(date.getMonth() + months);
  return date;
};

export function calculateDifference(
  cp: number,
  fillPrice: number,
  decision: "Buy" | "Sell"
): number {
  if (
    (cp >= fillPrice && decision === "Buy") ||
    (cp < fillPrice && decision === "Sell")
  ) {
    return Math.abs(cp - fillPrice);
  } else {
    return -Math.abs(cp - fillPrice);
  }
}
