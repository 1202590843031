export const Types = {
  LIVE: "live",
  VIRTUAL: "virtual",
};
export enum BrokerId {
  Blofin = 7,
  Alpaca = 4,
  AP = 8,
  BlueOcean = 10,
}
export enum BrokerName {
  Blofin = "blofin",
  Alpaca = "alpaca",
  AP = "Level2",
  BO = "BO",
  blueOcean = "BlueOceanFinancials-Server",
}
export interface UserInfo {
  user: {
    email: string;
    first_name: string;
    last_name: string;
    unique_key: string;
    profile_image: string;
    trading_challenge: string;
    description: string;
    is_active: boolean;
    user_type: string;
    meta_data: string;
  };
  preference: {
    country: string;
    trading_yoe: string;
    risk_class: string;
    language: string;
    asset_class: string;
    notification_preference: any;
    nudges_tracker: string;
    walkthrough_status: string;
    webhook: string;
    send_email: string;
  };
  is_old_user: boolean;
  user_id: string;
  hmac: string;
  token: string;
  subscription: string;
  start_date: string;
  redirect_after_login: string;
}

export interface StrategyData {
  strategy_details: {
    id: number;
    keygen: string;
    strategy_name: string;
    symbol: string;
    is_deleted?: number;
    timeframe: string;
    strategy_category?: any;
    add_ons?: string;
    is_copied?: number;
    is_notification?: number;
    backtest_type?: string;
    strategy_data?: string;
    canvas_data?: string;
    created_at: string;
  };
  epoch: string;
  action_metrics: Array<{
    trailling_sl_p: any;
    trailling_sl: any;
    order_type: string;
    node_id: string;
    stop_loss_p: any;
    stop_loss: any;
    size?: string;
    take_profit: any;
    take_profit_pips: any;
    stop_loss_pips: any;
    take_profit_price: any;
    stop_loss_price: any;
    take_profit_p: any;
  }>;
  deployment: {
    is_notification_deployed: boolean;
    is_virtual_deployed: boolean;
    is_live_deployed: boolean;
    live_depl_id: number;
  };
}

export enum DeploymentSize {
  FOREX_STR = "0.01",
  EQUITY_STR = "1",
  FOREX = 0.01,
  EQUITY = 1,
}

export enum TrendingTradersTableDataKey {
  Three = "3",
  Seven = "7",
  Fifteen = "15",
  Thirty = "30",
}

export enum AccentColor {
  GREEN = "#2a9d90",
  RED = "#e76e50",
  GRAY = "#e6e6e6",
  PINK = "#E91E63",
  BLUE = "#316ACD",
}

export enum SharpRatioString {
  DEFAULT = "Good",
  BELOW3 = "Poor",
  ABOVE3 = "Excellent",
}

export type Broker = {
  id: number;
  broker_name: string;
  image_url: string;
  doc_url: string;
  referral_link: string;
  offer_valid: number;
};

export type StrategyCategory = {
  id: number;
  label: string;
  description: string;
  is_valid: string;
};

export type Strategy = {
  id: number;
  keygen: string;
  strategy_name: string;
  symbol: string;
  is_deleted: number;
  timeframe: string;
  strategy_category: StrategyCategory;
  add_ons: string;
  is_copied: number;
  is_notification: number;
  backtest_type: string;
  strategy_data: string;
  canvas_data: string;
  created_at: string;
};

export type Item = {
  id: number;
  keys_json: string;
  meta_data: string;
  dep_flag: string;
  is_valid: string;
  order_trade_type: string;
  created_at: string;
  last_updated_at: string;
  broker: Broker;
  strategy: Strategy;
  user_id: string;
};

export type BrokerResponse = {
  items: Item[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export type BrokerStatsMetaResponse = {
  deployment_id: number;
  total_volume: number;
  actual_result: number;
  total_trades: number;
}[];

export type BrokerStatsTotal = {
  compute_hours: number;
  total_strategies_deployed: number;
  distinct_users: number;
  total_trades: number;
  total_win_p: number;
  total_compute_cost: number;
  avg_compute_cost_per_strategy: number;
  avg_compute_cost_per_trade: number;
  latency_graph: Record<string, string>;
};

export type DeployedScannersResponse = {
  items: DeployedScanner[];
  total: number;
  page: number;
  size: number;
  totalPages: number;
};

export type DeployedScanner = {
  id: number;
  universe: ScannerUniverse;
  strategy: ScannerStrategy;
  created_at: string;
  last_updated_at: string;
};

export type ScannerUniverse = {
  meta: {
    name: string;
  };
  keygen: string;
  universe_list: string[];
};

export type ScannerStrategy = {
  id: number;
  keygen: string;
  strategy_name: string;
  symbol: string;
  isDeleted: boolean;
  timeframe: string;
  category: any;
  addOns: any;
  isCopied: boolean;
  isNotificationEnabled: boolean;
  backtestType: string;
  strategyData: string;
  canvas_data: string;
  createdAt: string;
};
