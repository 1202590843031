import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, DialogContent } from "../ui/dialog";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import {
  brokerConnectModalAtom,
  guideModalAtom,
} from "@/Atoms/modal/DialogueAtoms";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { brokerFlagState } from "@/Atoms/auth/BrokerFlag";
import { useUserContext } from "@/contexts/userContext";
import { API_URL } from "@/config";
import axios, { AxiosError } from "axios";
import { getAccountBalances, useToastHelper } from "@/helpers/helperFunctions";
import { useTranslation } from "react-i18next";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import { HoverCard } from "@radix-ui/react-hover-card";
import { HoverCardContent, HoverCardTrigger } from "../ui/hover-card";
import { BiSolidHelpCircle } from "react-icons/bi";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import Link from "next/link";
import Loader from "../Loader";
import Image from "next/image";
import { BsExclamationCircle } from "react-icons/bs";
import { Loader2 } from "lucide-react";
import { MdDone } from "react-icons/md";
import { FiCopy } from "react-icons/fi";
import { useDarkTheme } from "@/contexts/DarkTheme";
import {
  usePostRevokeBrokerKeys,
  usePostSubmitBrokerKeys,
} from "@/api/brokerApi";

interface IFormValues {
  apiKey?: string;
  apiSecret?: string;
  passphrase?: string;
}
const BLOFIN_IPS = "13.201.74.209, 65.0.127.182, 43.204.111.49";

function BrokerConnectModal() {
  const connectForm = useForm<IFormValues>({
    mode: "onSubmit",
  });
  const [showConnectForm, setShowConnectForm] = useRecoilState(
    brokerConnectModalAtom
  );

  const [accountFunds, setAccountFunds] = useRecoilState(brokerFlagState);
  const [SCTab, setSCTab] = useState<any>(4);
  const [isIpCopied, setIpCopied] = useState(false);

  const { user: userInfo } = useUserContext();
  const { toast } = useToastHelper();
  const { t } = useTranslation("layout");
  const { darkTheme, setDarkTheme } = useDarkTheme();

  const {
    mutateAsync: revokeBrokerKeys,
    isPending: isRevokeLoading,
    isSuccess: isRevokeSuccess,
    isError: isRevokeError,
    error: revokeError,
  } = usePostRevokeBrokerKeys();
  const {
    mutateAsync: submitBrokerKeys,
    isPending: isSubmitLoading,
    data: submitData,
    isSuccess: isSubmitSuccess,
    isError: isSubmitError,
    error: submitError,
  } = usePostSubmitBrokerKeys();
  const {
    brokerConnected,
    connectedBroker,
    brokerConfigLoading,
    apiCallingLoading,
  } = useMemo(() => {
    const connectedBroker = accountFunds?.liveFunds?.find(
      (item: any) => item.broker_id === SCTab
    );
    return {
      brokerConnected: !!connectedBroker,
      connectedBroker: connectedBroker?.broker_details,
      brokerConfigLoading: accountFunds?.loading,
      apiCallingLoading: accountFunds?.apiCalling,
    };
  }, [accountFunds, SCTab]);

  const isSaving = isSubmitLoading || isRevokeLoading;
  const [showGuideModal, setShowGuideModal] = useRecoilState(guideModalAtom);

  const handleRevokeBrokerKeys = async () => {
    await revokeBrokerKeys({
      userId: userInfo?.user_id,
      key: String(SCTab),
    });
  };

  const handleSubmitBrokerKeys: SubmitHandler<IFormValues> = async (
    values,
    event
  ) => {
    event?.preventDefault(); // Prevent form submission default behavior if applicable

    const { apiKey, apiSecret, passphrase } = values as Required<IFormValues>;

    await submitBrokerKeys({
      userId: userInfo?.user_id,
      key: apiKey,
      secret: apiSecret,
      brokerId: SCTab,
      passphrase: SCTab === 7 ? passphrase : "s",
    });
  };

  const changeTab = (key: string) => {
    setSCTab(key);
  };

  const copyBlofinIps = useCallback(() => {
    navigator.clipboard.writeText(BLOFIN_IPS.replaceAll(", ", " "));
    setIpCopied(true);
    setTimeout(() => {
      setIpCopied(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (isRevokeSuccess) {
      toast({ title: "Broker keys revoked successfully" });
      setAccountFunds((prev) =>
        prev.isConnected ? { ...prev, isConnected: false } : prev
      );
      getAccountBalances(userInfo, setAccountFunds, t("funds.virtual"));
      setShowConnectForm(false);
    } else if (isRevokeError && revokeError) {
      const errMessage = revokeError?.message;
      if (
        errMessage !== "Please upgrade to the pro plan to access this feature"
      ) {
        toast({
          title: errMessage || t("errorWhileDisconnectingTheBroker"),
          variant: "destructive",
        });
      } else {
        toast({
          title: t("errorWhileDisconnectingTheBroker"),
          variant: "destructive",
        });
      }
    }
  }, [isRevokeLoading, isRevokeSuccess, isRevokeError]);

  useEffect(() => {
    if (isSubmitSuccess) {
      toast({
        title: submitData?.data?.message,
      });
      setAccountFunds((prev) => ({
        ...prev,
        isConnected: true,
      }));
      connectForm.setValue("apiKey", "");
      // connectForm.setValue("apiSecret", "");
      connectForm.setValue("passphrase", "");
      getAccountBalances(userInfo, setAccountFunds, t("funds.virtual"));
      setShowConnectForm(false);
    } else if (isSubmitError && submitError) {
      console.log(submitError);
      if (
        submitError?.message !==
        "Please upgrade to the pro plan to access this feature"
      ) {
        toast({
          title: submitError?.message || t("errorWhileConnectingTheBroker"),
          variant: "destructive",
        });
      }
    }
  }, [isSubmitLoading, isSubmitSuccess, isSubmitError]);
  return (
    <Dialog
      open={true}
      onOpenChange={() => {
        setShowConnectForm(false);
        connectForm.clearErrors();
        connectForm.reset();
      }}
    >
      <DialogContent>
        <Form {...connectForm}>
          <form
            className="h-full xl:flex xl:flex-col"
            method="post"
            onSubmit={
              brokerConnected
                ? connectForm.handleSubmit(handleRevokeBrokerKeys)
                : connectForm.handleSubmit(handleSubmitBrokerKeys)
            }
            id="strategy-form"
          >
            <CardHeader className="px-0">
              <CardTitle>
                <div className="text-2xl whitespace-nowrap font-bold tracking-tight">
                  {t("connectTo")} {t("fpMarkets")}
                </div>
              </CardTitle>
              <Tabs
                onValueChange={(val) => {
                  changeTab(val);
                }}
                value={SCTab}
                className="w-full"
              >
                <TabsList className="w-full flex gap-2">
                  <TabsTrigger
                    //@ts-ignore
                    value={4}
                    disabled
                    className="!grow w-full"
                  >
                    Live Trading (Coming soon)
                  </TabsTrigger>
                  <TabsTrigger
                    //@ts-ignore
                    value={4}
                    className="!grow w-full"
                  >
                    {t("Alpaca")} ({t("virtualTrading")})
                  </TabsTrigger>

                </TabsList>
              </Tabs>

              {/* For Alpaca (Virtual Trading) */}

              {SCTab === 4 && (
                <div className="flex flex-col gap-6 pt-4">
                  <div>
                    <CardTitle className="text-lg">
                      {t("1. Open an Alpaca account")}
                    </CardTitle>
                    <CardDescription>
                      <a
                        href={"https://app.alpaca.markets/signup"}
                        className="underline"
                        target="_blank"
                      >
                        {t("Click here")}
                      </a>{" "}
                      {t("to open a free Virtual Trading account with Alpaca")}
                    </CardDescription>
                  </div>
                  <div>
                    <CardTitle className="text-lg">
                      {t("2. Connect to the API")}
                    </CardTitle>
                    <CardDescription>
                      {t(
                        "Enter your API credentials below to enable fully automated trading"
                      )}
                    </CardDescription>
                  </div>
                  <div></div>
                </div>
              )}

              {SCTab === 7 && (
                <div className="flex flex-col gap-6 pt-4">
                  <div>
                    <CardTitle className="text-lg">
                      {t("1. Open an Blofin account")}
                    </CardTitle>
                    <CardDescription>
                      <a
                        href={"https://partner.blofin.com/d/Level2"}
                        className="underline"
                        target="_blank"
                      >
                        {t("Click here")}
                      </a>{" "}
                      {t("to open a free Live Trading account with Blofin")}
                    </CardDescription>
                  </div>
                  <div>
                    <CardTitle className="text-lg">
                      {t("2. Connect to the API")}
                    </CardTitle>
                    <CardDescription>
                      {t(
                        "Enter your API credentials below to enable fully automated trading"
                      )}
                    </CardDescription>
                  </div>
                </div>
              )}
            </CardHeader>
            <CardContent className="px-0">
              <div className="flex flex-col mb-5 gap-5 w-full">
                <div className="w-full flex flex-col gap-2">
                  <FormField
                    control={connectForm.control}
                    name="apiKey"
                    rules={{
                      required: brokerConnected
                        ? false
                        : t("demoAccNumberIsRequired"),
                    }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <div className="flex relative gap-1">
                            <div>{t("demoAccountNumber")}</div>
                            <div className="cursor-pointer">
                              <HoverCard openDelay={100}>
                                <HoverCardTrigger
                                  className={brokerConnected ? "" : "hidden"}
                                >
                                  <BiSolidHelpCircle size={12} />
                                </HoverCardTrigger>
                                <HoverCardContent className="text-xs">
                                  {t("brokerAlreadyConnected")}
                                </HoverCardContent>
                              </HoverCard>
                            </div>
                          </div>
                        </FormLabel>
                        <FormControl>
                          <Input
                            autoComplete="off"
                            disabled={brokerConnected}
                            placeholder={t("yourMt5LoginNumber")}
                            {...field}
                            value={
                              brokerConnected
                                ? connectedBroker?.data
                                : field.value || ""
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {SCTab === 7 && (
                  <div className="w-full flex flex-col gap-2">
                    <FormField
                      control={connectForm.control}
                      name="passphrase"
                      rules={{
                        required: brokerConnected
                          ? false
                          : t("passwordIsRequired"),
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            <div className="flex group relative gap-1">
                              <div>{t("passphrase")}</div>
                              <div className="cursor-pointer">
                                <HoverCard openDelay={100}>
                                  <HoverCardTrigger
                                    className={brokerConnected ? "" : "hidden"}
                                  >
                                    <BiSolidHelpCircle size={12} />
                                  </HoverCardTrigger>
                                  <HoverCardContent className="text-xs">
                                    {t("brokerAlreadyConnected")}
                                  </HoverCardContent>
                                </HoverCard>
                              </div>
                            </div>
                          </FormLabel>
                          <FormControl>
                            <Input
                              autoComplete="off"
                              disabled={brokerConnected}
                              placeholder={t("passphrase1")}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
                <div className="w-full flex flex-col gap-2">
                  <FormField
                    control={connectForm.control}
                    name="apiSecret"
                    rules={{
                      required: brokerConnected
                        ? false
                        : t("apiServerIsRequired"),
                    }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <div className="flex group relative gap-1">
                            <div>{t("server")}</div>
                            <div className="cursor-pointer">
                              <HoverCard openDelay={100}>
                                <HoverCardTrigger
                                  className={brokerConnected ? "" : "hidden"}
                                >
                                  <BiSolidHelpCircle size={12} />
                                </HoverCardTrigger>
                                <HoverCardContent className="text-xs">
                                  {t("brokerAlreadyConnected")}
                                </HoverCardContent>
                              </HoverCard>
                            </div>
                          </div>
                        </FormLabel>
                        <FormControl>
                          <Input
                            autoComplete="off"
                            disabled={brokerConnected}
                            placeholder={t("theMt5ServerName")}
                            {...field}
                            value={
                              brokerConnected ? "********" : field.value || ""
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {SCTab === 7 && (
                  <div className="text-xs text-muted-foreground items-center">
                    <span className="flex gap-1 font-bold">
                      {t(
                        "Paste these IP addresses in the IP whitelist section on Blofin"
                      )}
                      <span>:</span>
                      <HoverCard openDelay={100}>
                        <HoverCardTrigger
                          asChild
                          className="inline-block cursor-pointer"
                        >
                          <span>
                            <BiSolidHelpCircle size={16} />
                          </span>
                        </HoverCardTrigger>
                        <HoverCardContent className="whitespace-normal font-normal">
                          {t(
                            "It is essential to paste this IP address and select the trade permission or trades will not be triggered"
                          )}
                        </HoverCardContent>
                      </HoverCard>
                    </span>
                    <div
                      className="flex flex-row gap-2 items-center"
                      onClick={() => copyBlofinIps()}
                    >
                      <Button disabled={isIpCopied} variant="ghost" size="icon">
                        {isIpCopied ? <MdDone /> : <FiCopy />}
                      </Button>
                      {BLOFIN_IPS}
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
            <CardFooter className="grow px-0">
              <div className="flex w-full h-full">
                <div className="flex flex-col w-full float-right h-full">
                  <div className="flex flex-col w-full lg:flex-row float-right">
                    {brokerConfigLoading ? (
                      <div className="flex w-full justify-center">
                        <Loader />
                      </div>
                    ) : (
                      <div className="w-full">
                        <div className="gap-2 float-left">
                          <Link
                            target="_blank"
                            href={
                              SCTab === 7
                                ? "https://blofin.com/invite/Level2"
                                : "https://app.alpaca.markets/signup"
                            }
                          >
                            {SCTab === 7 ? (
                              <Image
                                width={115}
                                height={60}
                                src={
                                  darkTheme
                                    ? "/images/logos/blofin_dark.png"
                                    : "/images/logos/blofin_light.png"
                                }
                                alt="alpaca-logo"
                              />
                            ) : (
                              <Image
                                width={115}
                                height={60}
                                src={
                                  darkTheme
                                    ? "/images/logos/alpaca/alpaca_dark.png"
                                    : "/images/logos/alpaca/yellow-black-alpaca-logo.svg"
                                }
                                alt="alpaca-logo"
                              />
                            )}
                          </Link>
                        </div>
                        <Button
                          disabled={isSaving || apiCallingLoading}
                          className="text-sm gap-2 float-right"
                        >
                          {brokerConnected ? t("Revoke") : t("connect")}
                          {isSaving || apiCallingLoading ? (
                            <Loader2 className="ml-2 h-5 w-5 animate-spin" />
                          ) : (
                            <BsExclamationCircle />
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="text-xs text-muted-foreground flex gap-1 items-center">
                    {t("Not sure how to connect the API.")}{" "}
                    <Button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowGuideModal({ isOpen: true, link: "" });
                      }}
                      variant="link"
                      className="p-0 text-xs text-[var(--marketplace-blue-new)]"
                    >
                      {t("Click here")}
                    </Button>
                  </div>
                </div>
              </div>
            </CardFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default BrokerConnectModal;
